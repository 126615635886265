import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DialogService } from 'app/service/dialog.service';
import { TicketManagerService } from 'app/service/ticket-manager.service';
import { DialogMessageComponent } from '../dialog-message/dialog-message.component';
import { DataService } from 'app/service/data.service';
import { Constant } from 'app/config/constants';

@Component({
  selector: 'dialog-confirm-web-refund-ticket',
  templateUrl: './dialog-confirm-web-refund-ticket.component.html',
  styleUrls: ['./dialog-confirm-web-refund-ticket.component.scss']
})
export class DialogConfirmWebRefundTicketComponent implements OnInit {
  currentLang: any;
  listOrderId: string;
  salesAmount: number;
  refundAmount: number;
  refundFee: number;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private translateService: TranslateService,
    private ticketManagerService: TicketManagerService,
    private dialogService: DialogService,
    private dataService: DataService
  ) {
    this.currentLang = this.translateService.currentLang;
    this.listOrderId = data.listOrderId;
    this.salesAmount = data.salesAmount;
    this.refundAmount = data.refundAmount;
    this.refundFee = data.refundFee;
  }

  ngOnInit(): void {}

  /**
   * refund
   */
  refund(): void {
    const payload = {
      orderId: this.listOrderId,
      refundFee: +this.refundFee
    };
    this.ticketManagerService.refundWeb(payload).subscribe(response => {
      if (response.result == 'succeeded') {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('ticket-manager.sales-record.complete'),
            text: this.translateService.instant('ticket-manager.sales-record.web-refund-success')
          }
        });
        this.dataService.sendData([Constant.REFUND, this.listOrderId]);
        return;
      }
    });
    this.dialogService.closeAllDialog();
  }
}
